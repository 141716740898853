
import { defineComponent, ref, watchEffect } from 'vue'
import PlanCard from './PlanCard.vue'
import PlanWarranty from './PlanWarranty.vue'
import getPlans from './planItens'
import BaseYouNeedHelpMessage from '../base/BaseYouNeedHelpMessage.vue'

interface Plan {
  id: string
  identifier: string
  features: string
  name: string
  price: number
  checkoutURL: string
  recommended?: boolean
  locale?: string
  interval: string
}

export default defineComponent({
  components: {
    PlanCard,
    PlanWarranty,
    BaseYouNeedHelpMessage
},

  setup () {
    const plans = ref<Plan[]>([])
    const disclaimer = ref<string[]>([])
    const interval = ref<string>('year')

    watchEffect(async () => {
      try {
        const availablePlans = getPlans(['iniciante', 'avancado', 'rockStar'], interval.value, 'br')

        plans.value = availablePlans.plans
        disclaimer.value = availablePlans.disclaimer

      } catch (error) {
        console.log(error)
      }
    })

    return {
      plans,
      disclaimer,
      interval
    }
  }
})
