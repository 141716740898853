
import { computed, defineComponent, toRefs, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useNumeral } from '@/composables/useNumeral'
import PlanDetails from './PlanDetails.vue'
import { useProfile } from '@/composables/profile/useProfile'

export default defineComponent({
  components: { PlanDetails },
  props: {
    plan: {
      type: Object,
      default: () => ({})
    },
  },

  setup (props) {
    const { plan } = toRefs(props)

    const { profile } = useProfile()

    const { t } = useI18n()

    const { format, getCurrency } = useNumeral()

    const price = computed(() => {
      return format(unref(plan).price, '0.00')
    })

    const currency = computed(() => {
      return getCurrency().symbol
    })

    const totalPrice = computed(() => {
      const price = unref(plan).price
      if (unref(plan).interval === 'year') {
        return format(price * 12, '$0.00')  
      }
      return format(price, '$0.00')
    })

    const interval = computed(() => {
      const intervals = {
        month: t('sentence.month'),
        year: t('sentence.year')
      }
      return intervals[unref(plan).interval as keyof typeof intervals]
    })

    const popularPlan = computed(() => {
      return unref(plan).recommended === true
    })

    const checkoutURL = computed(() => {
      if (profile.value.id) {
        return unref(plan).checkoutURL + '?profile=' + profile.value.id
      }
      return unref(plan).checkoutURL
    })

    return {
      price,
      totalPrice,
      interval,
      popularPlan,
      currency,
      checkoutURL
    }
  }
})
