<template>
  <div
    class="card relative"
    :class="{recommended: plan.recommended}"
  >
    <div class="card-header flex flex-col justify-center items-center">
      <span
        class="text-2xl text-blue-600 tracking-wide font-semibold mb-2"
      >
        {{ plan.name }}
      </span>
      <span class="py-1 flex items-baseline">
        <span class="text-blue-600 font-semibold absolute -ml-6">{{ currency }}</span>
        <span class="text-4xl text-blue-600 font-semibold">{{ price }}</span>
        <span class="text-base text-blue-600 font-semibold ml-1">/{{ $t('sentence.month') }}</span>
      </span>
      <small class="h-8">
        <span v-if="plan.interval !== 'month'">
          {{ $t('page.plans.charged_value-dynamic', {value: totalPrice, interval}) }}
        </span>
      </small>

      <div>
      </div>
    </div>
    <div class="card-body h-auto mb-16">
      <plan-details :details="plan.features" />
    </div>
    <div class="card-footer absolute bottom-0 w-full">
      <a
        :href="checkoutURL"
        class="btn btn-primary mx-auto"
      >
        {{ $t('action.subscribe_now') }}
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useNumeral } from '@/composables/useNumeral'
import PlanDetails from './PlanDetails.vue'
import { useProfile } from '@/composables/profile/useProfile'

export default defineComponent({
  components: { PlanDetails },
  props: {
    plan: {
      type: Object,
      default: () => ({})
    },
  },

  setup (props) {
    const { plan } = toRefs(props)

    const { profile } = useProfile()

    const { t } = useI18n()

    const { format, getCurrency } = useNumeral()

    const price = computed(() => {
      return format(unref(plan).price, '0.00')
    })

    const currency = computed(() => {
      return getCurrency().symbol
    })

    const totalPrice = computed(() => {
      const price = unref(plan).price
      if (unref(plan).interval === 'year') {
        return format(price * 12, '$0.00')  
      }
      return format(price, '$0.00')
    })

    const interval = computed(() => {
      const intervals = {
        month: t('sentence.month'),
        year: t('sentence.year')
      }
      return intervals[unref(plan).interval as keyof typeof intervals]
    })

    const popularPlan = computed(() => {
      return unref(plan).recommended === true
    })

    const checkoutURL = computed(() => {
      if (profile.value.id) {
        return unref(plan).checkoutURL + '?profile=' + profile.value.id
      }
      return unref(plan).checkoutURL
    })

    return {
      price,
      totalPrice,
      interval,
      popularPlan,
      currency,
      checkoutURL
    }
  }
})
</script>

<style>
  @layer components {
    .recommended {
      @apply bg-gradient-to-t from-green-500 to-blue-600 transform-gpu -translate-y-4;
    }
    .recommended * {
      @apply text-white font-semibold;
    }
  }
</style>