<template>
  <div class="card flex flex-col lg:flex-row items-center justify-between w-full">
    <div class="flex-1 p-4 order-2 lg:order-1">
      <div class="text-2xl font-extrabold text-center lg:text-left">
        {{ $t('page.checkout.sentence.warranty_title') }}
      </div>
    </div>
    <div class="lg:flex-shrink-0 px-8 mb-8 lg:mb-0 order-1 lg:order-2">
      <img class="lg:w-40 transform scale-125" :src="warrantyImage">
    </div>
    <div class="flex-1 items-center p-4 text-sm text-gray-700 order-3">
      <p
        v-html="$t('page.checkout.sentence.warranty-html')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, unref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup () {

    const {
      locale,
    } = useI18n()
    
    const warrantyImage = computed(() => {
      const images = {
        "pt-br": 'warranty.svg',
        en: 'warranty_en.png'
      }

      const imageName = images[unref(locale) as keyof typeof images]
      return require(`@/assets/img/${imageName}`)
    })

    return {
      warrantyImage
    }
  }
})
</script>
