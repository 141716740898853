
import { computed, defineComponent, toRefs, unref } from 'vue'

export default defineComponent({
  props: {
    detail: {
      type: String,
      default: ''
    },
  },
  setup (props) {
    const { detail } = toRefs(props)

    const isVisible = computed(() => {
      return unref(detail).startsWith('+')
    })

    const text = computed(() => {
      return unref(detail).substring(1).trim()
    })

    return {
      isVisible,
      text
    }
  }
})
