
import { computed, defineComponent, unref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup () {

    const {
      locale,
    } = useI18n()
    
    const warrantyImage = computed(() => {
      const images = {
        "pt-br": 'warranty.svg',
        en: 'warranty_en.png'
      }

      const imageName = images[unref(locale) as keyof typeof images]
      return require(`@/assets/img/${imageName}`)
    })

    return {
      warrantyImage
    }
  }
})
