interface interval {
  month: {
    price: number
    checkoutURL: string
  },
  year: {
    price: number
    checkoutURL: string
  }
}

interface paymentData {
  iniciante: interval
  avancado:interval
  rockStar:interval
}

interface Plan {
  id: string
  identifier: string
  features: string
  name: string
  price: number
  checkoutURL: string
  recommended?: boolean
  locale?: string
  interval: string
}

const getFeatures = (identifier: string) => {
  const personalContentsCount = {
    iniciante: 8,
    avancado: 16,
    rockStar: 24,
  }

  const freeContentsCount = {
    iniciante: 15,
    avancado: 30,
    rockStar: 'Ilimitados',
  }

  return `
    + ${personalContentsCount[identifier as keyof typeof personalContentsCount]} conteúdos personalizados *
    + ${freeContentsCount[identifier as keyof typeof freeContentsCount]} posts da biblioteca de conteúdos **
    + 10 mil créditos mês ***
    + Conteúdos autorais ilimitados
    + Imagens HD Roalty Free
    + Hashtags por inteligência artificial
    + Editor de imagens integrado
    + Comparador de perfis do Instagram
    + Diagnóstico das redes sociais com IA
    ${identifier !== 'iniciante' ? '+' : '-'} Suporte prioritário
    `
    // ${identifier === 'rockStar' ? '+' : '-'}  Consultoria especializada mensal ****
}

const getPaymentData = (identifier: string, interval: string) => {
  const values: paymentData = {
    iniciante: {
      month: {
        price: 119,
        checkoutURL: 'https://app.manycontent.com/pages/checkout/1frezao5IKbviuHhjqjV'
      },
      year: {
        price: 74.8,
        checkoutURL: 'https://app.manycontent.com/pages/checkout/6MkkS5nYhc0G8GZCJGKW'
      }
    },
    avancado: {
      month: {
        price: 256,
        checkoutURL: 'https://app.manycontent.com/pages/checkout/nFdtPIQkscDf1mCb6hZX'
      },
      year: {
        price: 140.8,
        checkoutURL: 'https://app.manycontent.com/pages/checkout/J5hsO55SWph8CBqqiygN'
      }
    },
    rockStar: {
      month: {
        price: 444,
        checkoutURL: 'https://app.manycontent.com/pages/checkout/AjtkvNMSNTYmm6YRl6P1'
      },
      year: {
        price: 330.1,
        checkoutURL: 'https://app.manycontent.com/pages/checkout/BYyl6un2L7Ye5BKrB7xn'
      }
    },
  }

  return values[identifier as keyof paymentData][interval as keyof interval]
}

const getPlanName = (identifier: string): string => {
  const planNames = {
    iniciante: 'Iniciante',
    avancado: 'Avançado',
    rockStar: 'RockStar',
  }

  return planNames[identifier as keyof typeof planNames]
}

const getDisclaimer = (): string[] => {
  return [
    'Os planos são recorrentes, ou seja, debitados automaticamente em seu cartão de crédito a cada renovação. O plano Anual possui descontos por ser pago no ato da compra, à vista.',
    '* Conteúdos criados pela Manycontent usando seus algoritmos de Inteligência artificial e jornalistas incríveis otimizados para buscar maior engajamento com sua audiência.',
    '** Uma coleção de Posts prontos e disponíveis de forma gratúita para todos os usuários da plataforma. Incluem conteúdos do tipo citação, datas comemorativas e ocasionais.',
    '*** Créditos são a moeda utilizada para gerar os conteúdos utilizando IA. Eles são descontados da sua conta toda vez que um novo conteúdo é gerado e renovados todos os meses.',
    '**** Uma consultoria agendada mensal com um especialista da Manycontent. O especialista junto com você analisará suas redes sociais e indicará ações para melhorar os seus resultados.'
  ]
}

const getPlans = (identifiers: string[], interval: string, locale: string = 'pt-br'): {plans: Plan[], disclaimer: string[]} => {
  const plans = identifiers.map(identifier => {
    const paymentData = getPaymentData(identifier, interval)

    return {
      id: identifier,
      identifier: identifier,
      features: getFeatures(identifier),
      name: getPlanName(identifier),
      price: paymentData.price,
      checkoutURL: paymentData.checkoutURL,
      recommended: identifier === 'avancado',
      locale: locale,
      interval
    }
  })

  return {
    plans,
    disclaimer: getDisclaimer()
  }
}

export default getPlans