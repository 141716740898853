
import { defineComponent, onBeforeMount, unref } from 'vue'
// import { fbPixel } from '@/plugins/facebook/pixel'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import PlansList from '@/components/plans/PlansList.vue'
import { useProfile } from '@/composables/profile/useProfile'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    DashboardContent,
    PlansList,
  },
  setup () {
    const router = useRouter()

    const {
      hasActiveSubscription
    } = useProfile()

    onBeforeMount(() => {
      if (unref(hasActiveSubscription)) {
        router.replace({ name: 'home' })
      }
    })

    // onMounted(() => {
    //   fbPixel('track', 'PageView')
    // })
  }
})
