
import { computed, defineComponent } from 'vue'
import PlanDetail from './PlanDetail.vue'

export default defineComponent({
  components: {
    PlanDetail
  },

  props: {
    details: {
      type: String,
      default: ''
    }
  },

  setup (props) {
    const features = computed(() => {
      return props.details.split('\n').map(detail => detail.trim())
    })

    return {
      features
    }
  }
})
