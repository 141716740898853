<template>
  <!-- <div
    class="flex flex-col justify-center items-center mx-auto"
    v-if="isLoading"
  >
    <div class="my-24">
      <i class="fas fa-spinner fa-spin"></i> {{ $t('sentence.loading') }}
    </div>
  </div> -->

  <div>
    <div class="text-4xl font-semibold text-center">
      {{ $t('page.checkout.sentence.pans_and_prices') }}
    </div>

    <div class="text-xl text-center my-4 lg:my-12">
      {{ $t('page.checkout.sentence.plans_message') }}
    </div>

    <div class="flex gap-4 justify-center w-full mb-8">
      <button
        class="btn btn-primary uppercase"
        :class="[ interval === 'month'
          ? ''
          : 'outline']"
        v-on:click="interval = 'month'"
      >
        {{ $t('sentence.monthly') }}
      </button>
      <button
        class="btn btn-primary uppercase"
        :class="[ interval === 'year'
          ? ''
          : 'outline']"
        v-on:click="interval = 'year'"
      >
      {{ $t('sentence.yearly') }}
      </button>
    </div>

    <div
      class="grid grid-cols-1 lg:grid-cols-3 gap-4 pt-4"
    >
      <plan-card
        v-for="plan in plans"
        :key="plan.id"
        :plan="plan"
      />
    </div>

    <div class="flex flex-col gap-2 text-xs my-8">
      <div class="italic"
        v-for="(item, index) in disclaimer"
        :key="index"
      >
        {{item}}
      </div>
    </div>

    <plan-warranty class="mt-12 lg:my-24" />

    <div class="text-sm text-center mt-12">
      <base-you-need-help-message />
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue'
import PlanCard from './PlanCard.vue'
import PlanWarranty from './PlanWarranty.vue'
import getPlans from './planItens'
import BaseYouNeedHelpMessage from '../base/BaseYouNeedHelpMessage.vue'

interface Plan {
  id: string
  identifier: string
  features: string
  name: string
  price: number
  checkoutURL: string
  recommended?: boolean
  locale?: string
  interval: string
}

export default defineComponent({
  components: {
    PlanCard,
    PlanWarranty,
    BaseYouNeedHelpMessage
},

  setup () {
    const plans = ref<Plan[]>([])
    const disclaimer = ref<string[]>([])
    const interval = ref<string>('year')

    watchEffect(async () => {
      try {
        const availablePlans = getPlans(['iniciante', 'avancado', 'rockStar'], interval.value, 'br')

        plans.value = availablePlans.plans
        disclaimer.value = availablePlans.disclaimer

      } catch (error) {
        console.log(error)
      }
    })

    return {
      plans,
      disclaimer,
      interval
    }
  }
})
</script>
