<template>
  <li
    class="flex justify-start items-start text-sm"
    v-if="isVisible"
  >
    <i class="fas fa-check text-blue-600 mt-1 mr-2"></i>
    <span>{{ text }}</span>
  </li>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs, unref } from 'vue'

export default defineComponent({
  props: {
    detail: {
      type: String,
      default: ''
    },
  },
  setup (props) {
    const { detail } = toRefs(props)

    const isVisible = computed(() => {
      return unref(detail).startsWith('+')
    })

    const text = computed(() => {
      return unref(detail).substring(1).trim()
    })

    return {
      isVisible,
      text
    }
  }
})
</script>
